import axios from 'axios';

const instance = axios.create({ baseURL: '/api' });
const getToken = () => localStorage.getItem('token');

instance.interceptors.request.use((config) => {
	config.headers.Authorization = getToken();
	return config;
});

instance.interceptors.response.use(
	({ data, status }) => {
		return data
	},
	({ response }) => {
		const { data, status } = response;
		return Promise.reject(data)
	}
);
export default instance;
