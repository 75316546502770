import Resource from './resource';

const Testimonial = function (data) { return Resource.apply(this, data); };

Object.assign(Testimonial.prototype, Resource.prototype, {

    resourceName: 'testimonial'

});

export default new Testimonial();
