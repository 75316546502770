<script>
export default {
  name: 'BaseInput',

  props: {
    label: {
      type: String,
      default: null,
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },

    labelText: {
      type: String,
      default: null,
    },
  },

  computed: {
    showLabel() {
      return this.label || this.labelText;
    },
  },
};
</script>
<template>
  <label v-once v-show="showLabel" :for="$attrs.id" class="mb-1">{{ labelText || label }}</label>
  <input
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue',$event.target.value)"
    @focus="$emit('focus')"
    @focusout="$emit('focusout')"
  />
</template>
