import http from './index';

const Resource = function (data) { return Object.assign(this, data); };

Object.assign(Resource.prototype, {

  cache: {},

  http,

  async attach({ parentId, childResource, resourceId }, method = 'post') {
    const postUrl = `/${this.resourceName}/${parentId}/${childResource}/${resourceId}`;

    const res = await this.http[method](postUrl, {});
    return res;
  },

  async createResource(payload) {
    const createUrl = `/${this.resourceName}`;
    const res = await this.http.post(createUrl, payload);

    return res;
  },

  async detach({ parentId, childResource, resourceId }) {
    const deleteUrl = `/${this.resourceName}/${parentId}/${childResource}/${resourceId}`;

    const res = await this.http.delete(deleteUrl, {});
    return res;
  },

  async deleteResource(id) {
    const deleteUrl = `/${this.resourceName}/${id}`;
    const res = await this.http.delete(deleteUrl);

    return res;
  },

  async getResource(id) {
    if (this.cache[id]) return this.cache[id];

    const getUrl = `/${this.resourceName}/${id}`;
    const res = await this.http.get(getUrl);

    if (res) this.cache[id] = res;

    return res;
  },

  async getAllResources(query = {}) {
    const getUrl = `/${this.resourceName}`;
    const res = await this.http.get(getUrl, { params: { ...query } });

    return res;
  },

  async updateResource(resource) {
    const { id, ...payload } = resource;
    const putUrl = `/${this.resourceName}/${id}`;
    const res = await this.http.put(putUrl, payload);

    return res;
  },

});

export default Resource;
