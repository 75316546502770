import Resource from './resource';

const Listing = function (data) { return Resource.apply(this, data); };

Object.assign(Listing.prototype, Resource.prototype, {

  resourceName: 'listing',

  async uploadCoverImage(formData) {
    const file = await this.http.post('/file', formData);
    return file;
  },

});

export default new Listing();
