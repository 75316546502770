import Resource from './resource';

const Member = function (data) { return Resource.apply(this, data); };

Object.assign(Member.prototype, Resource.prototype, {

    resourceName: 'member'

});

export default new Member();
