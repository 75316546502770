import Resource from './resource';

const Post = function (data) { return Resource.apply(this, arguments); };

Object.assign(Post.prototype, Resource.prototype, {

  resourceName: 'post',

  async uploadCoverImage(formData) {
    const file = await this.http.post('/file', formData);
    return file;
  },

});

export default new Post();
